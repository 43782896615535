// HomeBannerTab.js

import React from 'react';
import styles from '../../Css/Features/FeatureCRTab.module.css';
import IntegrationImg from '../../Img/Integration1.png';
import { FcApproval } from "react-icons/fc";

const FeatureIntegrationTab = () => {
    return (
        <div className={styles.container}>
            <div className={styles.imageContainer}>
                <img src={IntegrationImg} alt="ProductImage" className={styles.image} />
            </div>
            <div className={styles.textContainer}>
                <h2 className={styles.title2}>Integration we Support</h2>
                <h2 className={styles.title}><FcApproval />
                    We offer seamless integration with multiple tools to enhance your workflow within your existing environment,
                    ensuring a smooth and efficient experience.
                </h2>
                <h2 className={styles.title}><FcApproval />
                    We support integration with 40+ tools. 
                </h2>
            </div>
        </div>
    );
};

export default FeatureIntegrationTab;
