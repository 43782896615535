// HomeBannerTab.js

import React from 'react';
import styles from '../../Css/Home/HomeImpactTab.module.css';
import AIImage from '../../Img/impact.jpg';
import { FcApproval } from "react-icons/fc";

const HomeImpactTab = () => {
    return (
        <div className={styles.container}>

            <div className={styles.textContainer}>
                <h2 className={styles.title2}>IncIQs's Impact</h2>
                <h2 className={styles.title}><FcApproval /> Comprehensive Excellence </h2>
                <p >Users experience a unified solution that streamlines incident response across diverse scenarios, 
                    ensuring a robust and all-encompassing defense.</p>
                <h2 className={styles.title}><FcApproval /> Global Insight Advantage </h2>
                <p>Organizations gain a geographical perspective, enabling swift identification and response to regional challenges, 
                    ensuring a proactive and globally aware approach.</p>
                <h2 className={styles.title}><FcApproval /> Intelligent Automation Mastery</h2>
                <p>Automation, guided by intelligence, ensures not only efficiency but proactive decision-making, 
                    averting potential incidents before they escalate.</p>
                <h2 className={styles.title}><FcApproval /> Collaboration Excellence</h2>
                <p>IncIQs not only facilitates internal team collaboration but also enhances external collaboration with vendors during incidents, 
                    ensuring a unified and swift resolution.</p>
                <h2 className={styles.title}><FcApproval /> Security Assurance </h2>
                <p>In markets emphasizing compliance, IncIQs provides transparency, immutability, and enhanced security in incident records,
                     instilling confidence in the platform's reliability.</p>
            </div>
            <div className={styles.imageContainer}>
                <img src={AIImage} alt="ProductImage" className={styles.image} />
            </div>
        </div>
    );
};

export default HomeImpactTab;
