import React, { useState } from 'react';
import { TextField, Box, Typography } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import styles from '../../Css/IncBlog/IncBlogTab.module.css';
import { FcFaq } from "react-icons/fc";

const IncBlogTab = () => {
    // Sample data for blog posts
    const blogPosts = [
        { id: 1, title: 'About IncIQs', description: 'Welcome to IncIQs, the groundbreaking solution poised to transform incident response and change request across industries. IncIQs is not just a platform, it\'s an intelligent incident management revolution designed to tackle the myriad challenges organizations face today. In a landscape riddled with the complexities of IT environments, overwhelming alert volumes, and the limitations of manual processes, IncIQs emerges as a beacon of efficiency and intelligence. Our mission is clear: streamline incident response, empower teams with predictive analytics and AI-driven insights, and provide a comprehensive solution to the diverse spectrum of incident management challenges.', date: '2024-04-17' },
        { id: 2, title: 'IncIQs A.I', description: 'Intelligent Incident Management, AI-Driven Incident Insights, AI-Driven Business Insights, Monitor Compliance and Security, Monitor Vulnerability, AI-Driven Performance Analytics, AI-Driven Business Impact, AI-Driven Global Incident Heatmap', date: '2024-04-18' },
        { id: 3, title: 'What is Predictive Analytics?', description: 'Leverage advanced algorithms to predict and proactively address potential incidents before they impact operations or escalate. Enhance decision-making with insights derived from historical incident data.', date: '2024-04-19' },
        { id: 4, title: 'What is Automation Workflows?', description: 'Streamline incident resolution with customizable and automated workflows. Improve efficiency by automating routine tasks, allowing teams to focus on critical aspects of incident response.', date: '2024-04-19' },
        { id: 5, title: 'What is Integration Hub?', description: 'Facilitate seamless connectivity with third-party tools and services for a holistic incident management approach. Enhance collaboration by integrating diverse systems and consolidating information in a centralized hub.', date: '2024-04-19' },
        { id: 6, title: 'What is Mobile Command Center?', description: 'Empower on-the-go incident management with a dedicated mobile app. Provide real-time updates, enabling teams to coordinate and respond swiftly from anywhere.', date: '2024-04-19' },
        { id: 7, title: 'What is Performance Analytics?', description: 'Gain actionable insights into incident response performance through detailed analytics and reporting. Identify trends, bottlenecks, and areas for improvement in real-time.', date: '2024-04-20' },
        { id: 8, title: 'What is Compliance and Security Features?', description: 'Strengthen security posture with advanced features ensuring compliance with industry regulations. Implement robust security measures to safeguard against potential threats and vulnerabilities.', date: '2024-04-20' },
        { id: 9, title: 'What is On-Call Management Optimization?', description: 'Optimize on-call scheduling for teams with flexible and automated rotation adjustments. Improve resource allocation based on incident severity and individual expertise.', date: '2024-04-20' },
        { id: 10, title: 'What is Customer Support Integration?', description: 'Streamline customer-reported incident resolution by integrating seamlessly with customer support systems. Enhance communication and collaboration between incident response and customer support teams.', date: '2024-04-20' },
        { id: 11, title: 'What is Incident Simulation?', description: 'Conduct simulated incidents for team practice and process refinement. Enhance preparedness by allowing teams to simulate responses in a controlled environment.', date: '2024-04-21' },
        { id: 12, title: 'What is Customizable SLA Management?', description: 'Implement a sophisticated system for custom Service Level Agreement (SLA) monitoring and enforcement. Tailor SLAs to specific services, ensuring efficient and targeted incident resolution.', date: '2024-04-21' },
        { id: 13, title: 'What is AI-Driven Insights?', description: 'Harness the power of artificial intelligence to analyze incident data and provide actionable insights. Improve decision-making with intelligent recommendations and trend analysis.', date: '2024-04-21' },
        { id: 14, title: 'What is Global Incident Heatmap?', description: 'Visualize incidents on a global heatmap for quick identification of regional challenges. Enhance situational awareness and response coordination on a geographical scale.', date: '2024-04-21' },
        { id: 15, title: 'What is Real-time Business Impact Analysis?', description: 'Assess the real-time impact of incidents on key business metrics. Enable swift decision-making by understanding the immediate business consequences of ongoing incidents with impact metrics.', date: '2024-04-21' },
        { id: 16, title: 'What is Vendor Collaboration Hub?', description: 'Foster collaboration with vendors during incidents through a dedicated platform. Enhance communication and coordination for incidents involving multiple service providers.', date: '2024-04-21' },
        { id: 17, title: 'What is Ecosystem Marketplace?', description: 'Build an ecosystem of third-party add-ons and integrations to enhance platform capabilities. Provide users with a marketplace for expanding and customizing their incident management toolkit.', date: '2024-04-21' },
        { id: 18, title: 'What is Blockchain-powered and Storage Incident Logging?', description: 'Ensure the security and integrity of incident data with a tamper-proof blockchain-powered logging system. Enhance transparency and compliance with secure and immutable incident records.', date: '2024-04-21' },
        { id: 19, title: 'Executive Overview Dashboard', description: 'Components: Key performance indicators (KPIs), incident trends, and overall system health. Benefits: Provides business owners with a high-level snapshot of incident management effectiveness and the impact on business operations.', date: '2024-04-22' },
        { id: 20, title: 'Financial Impact Dashboard', description: 'Components: Metrics related to incident costs, revenue impact, and financial performance during incidents. Benefits: Enables stakeholders to assess the financial implications of incidents and make informed decisions regarding resource allocation.', date: '2024-04-22' },
        { id: 21, title: 'Compliance and Security Dashboard', description: 'Components: Real-time compliance status, security threat alerts, and incident-related compliance metrics. Benefits: Assures stakeholders of regulatory compliance, highlights potential security threats, and ensures a proactive approach to security measures.', date: '2024-04-22' },
        { id: 22, title: 'Customer Dashboard', description: 'Components: Customer-reported incidents, resolution times, and customer feedback. Benefits: Allows stakeholders to gauge the impact of incidents on customer satisfaction and prioritize improvements in customer support.', date: '2024-04-22' },
        { id: 23, title: 'Operational Efficiency Dashboard', description: 'Components: Incident resolution times, efficiency metrics, and team performance indicators. Benefits: Provides insights into the operational efficiency of incident response teams, aiding in continuous improvement efforts.', date: '2024-04-22' },
        { id: 24, title: 'Incident Dashboard', description: 'Components: Long-term incident trends, predictive analytics insights, and resource allocation recommendations. Benefits: Assists business owners in strategic planning by offering insights into incident trends, future resource needs, and potential areas for innovation.', date: '2024-04-22' },
        { id: 25, title: 'IncIQs\'s Dashboard', description: 'The Business Board is a dynamic platform featuring specialized dashboards for strategic insights. The Executive Overview Dashboard offers a high-level snapshot of incident management effectiveness. The Financial Impact Dashboard assesses incident costs, revenue impact, and financial performance. The Compliance and Security Dashboard ensures proactive security measures and compliance adherence. The Customer Dashboard focuses on customer satisfaction, providing real-time insights. The Operational Efficiency Dashboard delivers metrics for incident resolution times and team performance. Altogether, The Business Board empowers organizations with a comprehensive and data-driven approach to decision-making across key operational dimensions.', date: '2024-04-22' },
    ];

    // State and functions for search and pagination
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const postsPerPage = 5;

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1);
    };

    const filteredPosts = blogPosts.filter(post =>
        post.title.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = filteredPosts.slice(indexOfFirstPost, indexOfLastPost);

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    return (
        <div className={styles.container}>
            <TextField
                label="Search Blog Posts"
                variant="outlined"
                className={styles.searchInput}
                value={searchQuery}
                onChange={handleSearchChange}
                style={{
                    marginLeft: '30%',
                    marginBottom: '4%'
                }}
            />

            <Box className={styles.postContainer}>
                {currentPosts.map(post => (
                    <div key={post.id} className={styles.postBox}>
                        <FcFaq fontSize={25} />
                        <Typography variant="h6">{post.title}</Typography>
                        <Typography variant="body1">{post.description}</Typography>
                        <Typography className={styles.postDate}>Post Date : {post.date}</Typography>
                    </div>
                ))}
            </Box>

            <Pagination
                count={Math.ceil(filteredPosts.length / postsPerPage)}
                page={currentPage}
                onChange={handleChangePage}
                className={styles.pagination}
            />
        </div>
    );
};

export default IncBlogTab;
