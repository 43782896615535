// HomeBannerTab.js

import React from 'react';
import styles from '../../Css/Home/HomeDashboardTab.module.css';
import dashboardImage from '../../Img/dashboard.png';
import { FcDoughnutChart } from "react-icons/fc";

const HomeDashboardTab = () => {
    return (
        <div className={styles.container}>
            <div className={styles.imageContainer}>
                <img src={dashboardImage} alt="ProductImage" className={styles.image} />
            </div>
            <div className={styles.textContainer}>
                <h2 className={styles.title2}>IncIQs's Dashboard</h2>
                <p className={styles.ptext}>
                The Business Board is a dynamic platform featuring specialized dashboards for strategic insights. 
                The Executive Overview Dashboard offers a high-level snapshot of incident management effectiveness. 
                The Financial Impact Dashboard assesses incident costs, revenue impact, and financial performance. 
                The Compliance and Security Dashboard ensures proactive security measures and compliance adherence. 
                The Customer Dashboard focuses on customer satisfaction, providing real-time insights. 
                The Operational Efficiency Dashboard delivers metrics for incident resolution times and team performance. 
                Altogether, The Business Board empowers organizations with a comprehensive and data-driven approach to decision-making across key operational dimensions.
                </p>
                <h2 className={styles.title}><FcDoughnutChart /> Executive Overview Dashboard</h2>
                <h2 className={styles.title}><FcDoughnutChart /> Financial Impact Dashboard</h2>
                <h2 className={styles.title}><FcDoughnutChart /> Compliance and Security Dashboard</h2>
                <h2 className={styles.title}><FcDoughnutChart /> Customer Dashboard</h2>
                <h2 className={styles.title}><FcDoughnutChart /> Operational Efficiency Dashboard </h2>
                <h2 className={styles.title}><FcDoughnutChart /> Incident Dashboard </h2>
                <h2 className={styles.title}><FcDoughnutChart /> User Impact Dashboard </h2>

            </div>
        </div>
    );
};

export default HomeDashboardTab;
