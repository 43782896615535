import React from 'react';
import Header from '../Main/Header';
import HomeBannerTab from '../../Component/Home/HomeBannerTab';
import HomeTagTab from '../../Component/Home/HomeTagTab';
import HomeKeyFeatureTab from '../../Component/Home/HomeKeyFeatureTab';
import HomeAdvancedFeatureTab from '../../Component/Home/HomeAdvancedFeatureTab';
import HomeAITab from '../../Component/Home/HomeAITab';
import HomeDashboardTab from '../../Component/Home/HomeDashboardTab';
import HomeImpactTab from '../../Component/Home/HomeImpactTab';
import Footer from '../Main/Footer';
import HomeAnnouncement from '../../Component/Home/HomeAnnouncement';
import HomeAdvanceMonitorTab from '../../Component/Home/HomeAdvanceMonitorTab';
import HomeAppTab from '../../Component/Home/HomeAppTab';

const Home = () => {

  return (
    <div>
      <Header/>
      <HomeBannerTab/>
      <HomeTagTab/>
      <HomeKeyFeatureTab/>
      <HomeAnnouncement/>
      <HomeAITab/>
      <HomeAdvancedFeatureTab/>  
      <HomeDashboardTab/>
      <HomeAdvanceMonitorTab/>
      <HomeImpactTab/> 
      <HomeAppTab/>
      <Footer/>
    </div>
  );
};

export default Home;
