// HomeBannerTab.js

import React from 'react';
import styles from '../../Css/Features/FeatureIssueTrackTab.module.css';
import RealTimeMonitorImg from '../../Img/RealTimeMonitor.png';
import { FcApproval } from "react-icons/fc";

const FeatureRealTimeTab = () => {
    return (
        <div className={styles.container}>
            <div className={styles.textContainer}>
                <h2 className={styles.title2}>IncIQs's RealTime Monitors</h2>
                <h2 className={styles.title}><FcApproval /> Threshold Alert</h2>
                <h2 className={styles.title}><FcApproval /> Change Alert</h2>
                <h2 className={styles.title}><FcApproval /> Anomaly Alert</h2>
            </div>
            <div className={styles.imageContainer}>
                <img src={RealTimeMonitorImg} alt="ProductImage" className={styles.image} />
            </div>

        </div>
    );
};

export default FeatureRealTimeTab;
