import React from 'react';
import FooterInfoTab from '../../Component/Footer/FooterInfoTab';

const Footer = () => {
    return (
        <div>
            <FooterInfoTab/>
        </div>
    );
};

export default Footer;
