import React from 'react';
import { AppBar, Toolbar, Typography, Button, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import logo from '../../Img/logo3.png'; 
import styles from '../../Css/Main/Header.module.css'; 

export default function Header() {
    const buttonStyle = {
        marginLeft: '1%',
        cursor: 'pointer',
        '&:hover': {
            color: '#EBEBEB',
            backgroundColor: '#ff2f00',
            borderRadius: '15px',
            boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.4), -2px 2px 4px rgba(0, 0, 0, 0.4), 2px -2px 4px rgba(0, 0, 0, 0.4), -2px -2px 4px rgba(0, 0, 0, 0.4)'
        }
    };

    const welcomeStyle = {
        marginLeft: 'auto'
    };

    const logoStyle = {
        height: '40px',
        marginRight: '10px',
        borderRadius: '20px',
        boxShadow: '3px 3px 6px rgba(255, 64, 0, 0.6), -3px 3px 6px rgba(255, 64, 0, 0.6), 3px -3px 6px rgba(255, 64, 0, 0.6), -3px -3px 6px rgba(255, 64, 0, 0.6)'
    };

    const appBarStyle = {
        backgroundColor: '#230436',
        borderRadius: '0 0 20px 20px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.4), 0 4px 8px rgba(0, 0, 0, 0.5), 0 8px 16px rgba(0, 0, 0, 0.5), 0 8px 16px rgba(0, 0, 0, 0.5)',
        zIndex: '-1'
    };

    return (
        <Box position="sticky" top={0} zIndex={1}>
            <AppBar style={appBarStyle}>
                <Toolbar>
                    <img src={logo} alt="Logo" style={logoStyle} /> 
                    <Typography variant="h6">
                        <strong>IncIQs</strong> &nbsp;  &nbsp; 
                    </Typography>
                    <Button color="inherit" component={Link} to="/" style={buttonStyle}>
                        <strong>Home</strong>
                    </Button>
                    <Button color="inherit" component={Link} to="/Features" style={buttonStyle}>
                        <strong>Features</strong>
                    </Button>
                    <Button color="inherit" component={Link} to="/About" style={buttonStyle}>
                        <strong>About</strong>
                    </Button>
                    <Button color="inherit" component={Link} to="/Pricing" style={buttonStyle}>
                        <strong>Pricing</strong>
                    </Button>
                    <Button color="inherit" component={Link} to="/IncBlog" style={buttonStyle}>
                        <strong>IncBlog</strong>
                    </Button>
                    <Button color="inherit" component={Link} to="/Career" style={buttonStyle}>
                        <strong>Career</strong>
                    </Button>
                    <Button color="inherit" component={Link} to="/Contact" style={buttonStyle}>
                        <strong>Contact</strong>
                    </Button>
                    <Typography variant="body1" style={welcomeStyle}>
                        <strong>Welcome To IncIQs</strong>
                    </Typography>
                </Toolbar>
            </AppBar>
        </Box>
    );
}
