import React from 'react';
import { Box, Typography } from '@mui/material';
import {
    FcPortraitMode, FcRadarPlot, FcMultipleInputs, FcTouchscreenSmartphone, FcScatterPlot,
    FcPrivacy, FcCallTransfer, FcAssistant, FcElectronics, FcElectroDevices, FcGlobe, FcHeatMap
    ,FcTodoList, FcSelfServiceKiosk,FcFilingCabinet,FcLockLandscape, FcConferenceCall,FcEngineering
    ,FcExpired, FcCapacitor
} from 'react-icons/fc'; // Import icons as needed
import styles from '../../Css/Features/KeyFeaturesTab.module.css'; // Assuming you have a CSS file for this component

const KeyFeaturesTab = () => {
    // Array of features with IDs, titles, and icons
    const features = [
        { id: 1, title: 'Predictive Analytics', icon: <FcPortraitMode className={styles.reactIcon} /> },
        { id: 2, title: 'Automation Workflows', icon: <FcRadarPlot className={styles.reactIcon} /> },
        { id: 3, title: 'Integration Hub', icon: <FcMultipleInputs className={styles.reactIcon} /> },
        { id: 4, title: 'Mobile Command Center', icon: <FcTouchscreenSmartphone className={styles.reactIcon} /> },
        { id: 5, title: 'Performance Analytics', icon: <FcScatterPlot className={styles.reactIcon} /> },
        { id: 6, title: 'Compliance and Security Features', icon: <FcPrivacy className={styles.reactIcon} /> },
        { id: 7, title: 'On-Call Management Optimization', icon: <FcCallTransfer className={styles.reactIcon} /> },
        { id: 8, title: 'Customer Support Integration', icon: <FcAssistant className={styles.reactIcon} /> },
        { id: 9, title: 'AI-Driven Insights', icon: <FcElectronics className={styles.reactIcon} /> },
        { id: 10, title: 'Incident Simulation', icon: <FcElectroDevices className={styles.reactIcon} /> },
        { id: 11, title: 'Global Incident Heatmap', icon: <FcGlobe className={styles.reactIcon} /> },
        { id: 12, title: 'Real-time Business Impact Analysis', icon: <FcHeatMap className={styles.reactIcon} /> },
        { id: 13, title: 'Change Request', icon: <FcTodoList className={styles.reactIcon} /> },
        { id: 14, title: 'Custom Montor Setup', icon: <FcEngineering className={styles.reactIcon} /> },
        { id: 15, title: 'RealTime Monitor', icon: <FcSelfServiceKiosk className={styles.reactIcon} /> },
        { id: 16, title: 'RealTime User Impact', icon: <FcGlobe className={styles.reactIcon} /> },
        { id: 17, title: 'Business Dashboard', icon: <FcLockLandscape className={styles.reactIcon} /> },
        { id: 18, title: 'Team Tracker', icon: <FcConferenceCall className={styles.reactIcon} /> },
        { id: 15, title: 'Infrastructure Monitor', icon: <FcFilingCabinet className={styles.reactIcon} /> },
        { id: 15, title: 'Third Party integration ', icon: <FcCapacitor className={styles.reactIcon} /> },
        { id: 15, title: 'User Notification/Escalation', icon: <FcExpired className={styles.reactIcon} /> },

    ];

    return (
        <div>
            <h1 className={styles.titleHeader}>Our Features</h1>
            <Box className={styles.container}>
                {features.map((feature) => (
                    <div key={feature.id} className={styles.featureBox}>
                        <div className={styles.iconContainer}>
                            {feature.icon} {/* Render the icon component */}
                        </div>
                        <h1 variant="h6" className={styles.title}>
                            {feature.title}
                        </h1>
                    </div>
                ))}
            </Box>
        </div>
    );
};

export default KeyFeaturesTab;
