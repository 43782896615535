import React from 'react'
import Header from './Main/Header'
import ComingSoon from './Main/ComingSoon'

export default function Pricing() {
  return (
    <div>
      <Header />
      <ComingSoon />
    </div>
  )
}
