import React from 'react'
import Header from './Main/Header'
import CareerTab from '../Component/Career/CareerTab'

export default function Career() {
  return (
    <div>
        <Header/>
        <CareerTab/>
    </div>
  )
}
