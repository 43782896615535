import React from 'react';
import styles from '../../Css/Main/ComingSoon.module.css';

const ComingSoon = () => {
    return (
        <div className={styles.container}>
            <h1 className={styles.title}>Coming Soon</h1>
            <p className={styles.description}>
                Our website is currently under construction. We'll be live soon with exciting updates!
            </p>
        </div>
    );
};

export default ComingSoon;
