// HomeKeyFeatureTab.js

import React from 'react';
import styles from '../../Css/Home/HomeKeyFeatureTab.module.css';
import { FcElectricalSensor, FcBrokenLink, FcTouchscreenSmartphone, FcTimeline, FcSportsMode, FcAssistant } from "react-icons/fc";

const HomeKeyFeatureTab = () => {
    return (

        <div>
            <h1 className={styles.componentTitle}>Key Features</h1>
            <div className={styles.container}>
                <div className={styles.featureBox}>
                    <div className={styles.iconContainer}>
                        <FcElectricalSensor className={styles.reactIcon} />
                    </div>
                    <div className={styles.content}>
                        <h2 className={styles.title}>Predictive Analytics</h2>
                        <p className={styles.description}>
                            Enhance decision-making with insights derived from historical incident data.
                        </p>
                    </div>
                </div>

                <div className={styles.featureBox}>
                    <div className={styles.iconContainer}>
                        <FcBrokenLink className={styles.reactIcon} />
                    </div>
                    <div className={styles.content}>
                        <h2 className={styles.title}>Automation Workflows</h2>
                        <p className={styles.description}>
                            Improve efficiency by automating routine tasks, allowing teams to focus on critical aspects of incident response.
                        </p>
                    </div>
                </div>

                <div className={styles.featureBox}>
                    <div className={styles.iconContainer}>
                        <FcTouchscreenSmartphone className={styles.reactIcon} />
                    </div>
                    <div className={styles.content}>
                        <h2 className={styles.title}>Mobile Command Center</h2>
                        <p className={styles.description}>
                            Provide real-time updates, enabling teams to coordinate and respond swiftly from anywhere.
                        </p>
                    </div>
                </div>
                <div className={styles.featureBox}>
                    <div className={styles.iconContainer}>
                        <FcTimeline className={styles.reactIcon} />
                    </div>
                    <div className={styles.content}>
                        <h2 className={styles.title}>Performance Analytics</h2>
                        <p className={styles.description}>
                            Gain actionable insights into incident response performance through detailed analytics and reporting.
                        </p>
                    </div>
                </div>
                <div className={styles.featureBox}>
                    <div className={styles.iconContainer}>
                        <FcSportsMode className={styles.reactIcon} />
                    </div>
                    <div className={styles.content}>
                        <h2 className={styles.title}>On-Call Management Optimization</h2>
                        <p className={styles.description}>
                            Improve resource allocation based on incident severity and individual expertise.
                        </p>
                    </div>
                </div>
                <div className={styles.featureBox}>
                    <div className={styles.iconContainer}>
                        <FcAssistant className={styles.reactIcon} />
                    </div>
                    <div className={styles.content}>
                        <h2 className={styles.title}>Customer Support Integration</h2>
                        <p className={styles.description}>
                            Enhance communication and collaboration between incident response and customer support teams.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeKeyFeatureTab;
