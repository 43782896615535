import React from 'react'
import Header from './Main/Header'
import ComingSoon from './Main/ComingSoon'

export default function FAQ() {
  return (
    <div>
        <Header/>
        <ComingSoon/>
    </div>
  )
}
