import React from 'react'
import Header from './Main/Header'
import IncBlogTab from '../Component/IncBlog/IncBlogTab'

export default function IncBlog() {
  return (
    <div>
        <Header/>
        <IncBlogTab/>
    </div>

  )
}
