// HomeBannerTab.js

import React from 'react';
import styles from '../../Css/Home/HomeBannerTab.module.css';
import productImage from '../../Img/product.png';

const HomeBannerTab = () => {
    return (
        <div className={styles.container}>
            <div className={styles.textContainer}>
                <h2 className={styles.title2}>IncIQs</h2>
                <h2 className={styles.title}>Intelligent Incident Management Platform</h2>

            </div>
            <div className={styles.imageContainer}>
                <img src={productImage} alt="ProductImage" className={styles.image} />
            </div>
        </div>
    );
};

export default HomeBannerTab;
