
import React from 'react';
import styles from '../../Css/Contact/ContactTab.module.css';
import ContactImage from '../../Img/Contact.jpg';

const ContactTab = () => {
    return (
        <div className={styles.container}>
            <div className={styles.imageContainer}>
                <img src={ContactImage} alt="ProductImage" className={styles.image} />
            </div>
            <div className={styles.textContainer}>
                <div className={styles.inputGroup}>
                    <label htmlFor="name">Name:</label>
                    <input type="text" id="name" name="name" />
                </div>

                <div className={styles.inputGroup}>
                    <label htmlFor="email">Email:</label>
                    <input type="email" id="email" name="email" />
                </div>

                <div className={styles.inputGroup}>
                    <label htmlFor="subject">Subject:</label>
                    <input type="text" id="subject" name="subject" />
                </div>

                <div className={styles.inputGroup}>
                    <label htmlFor="message">Message:</label>
                    <textarea id="message" name="message"></textarea>
                </div>

                <button type="submit" className={styles.submitButton}>
                    Send Message
                </button>
            </div>
        </div>
    );
};

export default ContactTab;
