// HomeBannerTab.js

import React from 'react';
import styles from '../../Css/Features/FeatureCRTab.module.css';
import CRImage from '../../Img/cr.png';
import { FcApproval } from "react-icons/fc";

const FeatureCRTab = () => {
    return (
        <div className={styles.container}>
            <div className={styles.imageContainer}>
                <img src={CRImage} alt="ProductImage" className={styles.image} />
            </div>
            <div className={styles.textContainer}>
                <h2 className={styles.title2}>IncIQs's Change Request</h2>
                <h2 className={styles.title}><FcApproval /> Manage Change Request</h2>
                <h2 className={styles.title}><FcApproval /> Approve Change Request</h2>
                <h2 className={styles.title}><FcApproval /> Track Change Request</h2>
                <h2 className={styles.title}><FcApproval /> Change Request Progress</h2>

            </div>
        </div>
    );
};

export default FeatureCRTab;
