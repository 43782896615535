// HomeBannerTab.js

import React from 'react';
import styles from '../../Css/Home/HomeAITab.module.css';
import AIImage from '../../Img/HomeMonitor.png';
import { FcSelfServiceKiosk } from "react-icons/fc";

const HomeAdvanceMonitorTab = () => {
    return (
        <div className={styles.container}>
            <div className={styles.textContainer}>
                <h2 className={styles.title2}>IncIQs's Advance Monitors</h2>
                <h2 className={styles.title}><FcSelfServiceKiosk /> Metric Monitor</h2>
                <h2 className={styles.title}><FcSelfServiceKiosk /> Change Monitor</h2>
                <h2 className={styles.title}><FcSelfServiceKiosk /> Anomaly Monitor</h2>
                <h2 className={styles.title}><FcSelfServiceKiosk /> Forecast Monitor</h2>
                <h2 className={styles.title}><FcSelfServiceKiosk /> Host Monitor</h2>
                <h2 className={styles.title}><FcSelfServiceKiosk /> APM Monitor</h2>
                <h2 className={styles.title}><FcSelfServiceKiosk /> CI Monitor</h2>
                <h2 className={styles.title}><FcSelfServiceKiosk /> Error Tracking Monitor</h2>
                <h2 className={styles.title}><FcSelfServiceKiosk /> Event Monitor</h2>
                <h2 className={styles.title}><FcSelfServiceKiosk /> Integration Monitor</h2>
                <h2 className={styles.title}><FcSelfServiceKiosk /> Live Process Monitor</h2>
                <h2 className={styles.title}><FcSelfServiceKiosk /> Logs Monitor</h2>
                <h2 className={styles.title}><FcSelfServiceKiosk /> Network Check Monitor</h2>
                <h2 className={styles.title}><FcSelfServiceKiosk /> Real User Monitor</h2>
            </div>
            <div className={styles.imageContainer}>
                <img src={AIImage} alt="ProductImage" className={styles.image} />
            </div>
        </div>
    );
};

export default HomeAdvanceMonitorTab;
