// HomeTagTab.js

import React from 'react';
import styles from '../../Css/Home/HomeTagTab.module.css';

const HomeTagTab = () => {
    return (
        <div className={styles.container}>
            <div className={styles.textContainer}>
                <p className={styles.text}>
                Welcome to IncIQs, the groundbreaking solution poised to transform incident response and change request across industries. 
                IncIQs is not just a platform, it's an intelligent incident management revolution designed to tackle 
                the myriad challenges organizations face today. In a landscape riddled with the complexities of IT environments, 
                overwhelming alert volumes, and the limitations of manual processes, IncIQs emerges as a beacon of efficiency and 
                intelligence. Our mission is clear: streamline incident response, empower teams with predictive analytics and AI-driven insights, 
                and provide a comprehensive solution to the diverse spectrum of incident management challenges. 
                </p>
            </div>
        </div>
    );
};

export default HomeTagTab;
