import React from 'react';
import styles from '../../Css/Career/CareerTab.module.css';
import { FcBriefcase } from "react-icons/fc";
import { FaBriefcase } from "react-icons/fa";

const CareerTab = () => {
    return (
        <div className={styles.container}>
            <form className={styles.jobForm}>
                <h3 className={styles.formTitle}>Apply for a Job</h3>
                <input type="text" placeholder="Your Name" className={styles.inputField} />
                <input type="email" placeholder="Your Email" className={styles.inputField} />
                <select className={styles.inputField}>
                    <option value="">Select Job Position</option>
                    <option value="Software Engineer">Software Engineer</option>
                    <option value="UX/UI Designer">UX/UI Designer</option>
                    <option value="Java Automation QA Engineer">Java Automation QA Engineer</option>


                </select>
                <input type="file" accept=".pdf,.doc,.docx" className={styles.fileInput} />
                <textarea placeholder="Your Message" className={styles.inputField}></textarea>
                <button type="submit" className={styles.submitButton}>Submit Application</button>
            </form>


            <h3 className={styles.vacancies}> <FaBriefcase style={{ fontSize: '40px' }} /> : Our Job Vacancies</h3>


            <div className={styles.jobList}>
                <div className={styles.job}>
                    <h3 className={styles.jobTitle}>
                        <FcBriefcase style={{ fontSize: '30px' }} /> - Java Automation QA Engineer (3-7Years)</h3>
                    <p className={styles.jobDescription}>We are looking for a talented Java Selenium Automation QA engineer with 3-7 years of experience.
                        The candidate will be responsible for establishing the test automation process,
                        participating in evaluating and planning the automation roadmap,
                        maintaining and updating Selenium WebDriver scripts, and designing,
                        developing, and implementing a scalable test automation framework and strategy.
                        Also, should hold excellent verbal, written and analytical skills,
                        and be able to interact with application development teams, SME,
                        and project managers to understand requirements and communicate status.
                        And should be able to execute automated test iterations, track & report test results,
                        troubleshoot and coordinate the bug fixes. The individual should have a strong understanding
                        of agile processes and the related QA lifecycle and automation methodology.</p>
                    <h4 className={styles.techStack}>Skills : Java programming language (Core Java Basic,
                        TestNG, JUnit, Selenium, JBeheave, Planning,
                        API testing with Postman, and RESTful Services, Maven, GIT, JIRA, Any cloud, Database (SQL/NoSQl)
                        , Test Cases Authoring, Executing, Reporting.
                    </h4>
                </div>

            </div>
        </div>
    );
};

export default CareerTab;
