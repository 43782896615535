// HomeBannerTab.js

import React from 'react';
import styles from '../../Css/Home/HomeAppTab.module.css';
import mobile from '../../Img/mobile.png';
import { FcAndroidOs } from "react-icons/fc";

const HomeAppTab = () => {
    return (
        <div className={styles.container}>
            <div className={styles.imageContainer}>
                <img src={mobile} alt="ProductImage" className={styles.image} />
            </div>
            <div className={styles.textContainer}>
                <h2 className={styles.title2}> IncIQs's Mobile Apps
                </h2>
                <div className={styles.detailContainer}>
                    <FcAndroidOs size={50} style={{ paddingTop: '20px' }} />

                    <h2 className={styles.title}>
                        IncIQs's EMS :
                        <a href="https://drive.usercontent.google.com/download?id=1fnxpQ_huKn_LFHRrG0BdShVOQ0Vx46wu&export=download&authuser=0"
                            style={{ color: '#FB6904', marginLeft: '20px' }} target="_blank" rel="noopener noreferrer">Click to Download IncIQsEMS</a>
                        <br />
                        <br />
                        Authenticator :
                        <a href="https://drive.google.com/file/d/1ec-GYl06sdf764FDqIhQ_oWQTqFeS13o/view?usp=sharing"
                            style={{ color: '#FB6904', marginLeft: '20px' }} target="_blank" rel="noopener noreferrer">Click to Download AuthenticatorInc</a>

                    </h2>
                    <br />


                </div>
                <h2 className={styles.title}>
                    We are coming with more SOON!!
                </h2>
            </div>
        </div>
    );
};

export default HomeAppTab;
