// HomeKeyFeatureTab.js

import React from 'react';
import styles from '../../Css/Home/HomeAdvancedFeatureTab.module.css';
import { FcAutomatic, FcAnswers, FcBiohazard, FcGlobe, FcHeatMap, FcDataEncryption } from "react-icons/fc";
import { FaRobot } from "react-icons/fa";
const HomeAdvancedFeatureTab = () => {
    return (

        <div>
            <h1 className={styles.componentTitle}>Advanced Features</h1>
            <div className={styles.container}>
                <div className={styles.featureBox}>
                    <div className={styles.iconContainer}>
                        <FcAutomatic className={styles.reactIcon} />
                    </div>
                    <div className={styles.content}>
                        <h2 className={styles.title}>Incident Simulation</h2>
                        <p className={styles.description}>
                            Conduct simulated incidents for team practice and process refinement.
                        </p>
                    </div>
                </div>

                <div className={styles.featureBox}>
                    <div className={styles.iconContainer}>
                        <FcAnswers className={styles.reactIcon} />
                    </div>
                    <div className={styles.content}>
                        <h2 className={styles.title}>Customizable SLA Management</h2>
                        <p className={styles.description}>
                            Tailor SLAs to specific services, ensuring efficient and targeted incident resolution.
                        </p>
                    </div>
                </div>

                <div className={styles.featureBox}>
                    <div className={styles.iconContainer}>
                        <FaRobot className={styles.reactIcon} style={{color:'yellowgreen'}}/>
                    </div>
                    <div className={styles.content}>
                        <h2 className={styles.title}>AI-Driven Insights</h2>
                        <p className={styles.description}>
                            Improve decision-making with intelligent recommendations and trend analysis.
                        </p>
                    </div>
                </div>
                <div className={styles.featureBox}>
                    <div className={styles.iconContainer}>
                        <FcGlobe className={styles.reactIcon} />
                    </div>
                    <div className={styles.content}>
                        <h2 className={styles.title}>Global Incident Heatmap</h2>
                        <p className={styles.description}>
                            Visualize incidents on a global heatmap for quick identification of regional challenges.
                        </p>
                    </div>
                </div>
                <div className={styles.featureBox}>
                    <div className={styles.iconContainer}>
                        <FcHeatMap className={styles.reactIcon} />
                    </div>
                    <div className={styles.content}>
                        <h2 className={styles.title}>Real-time Business Impact Analysis</h2>
                        <p className={styles.description}>
                            Assess the real-time impact of incidents on key business metrics.
                        </p>
                    </div>
                </div>
                <div className={styles.featureBox}>
                    <div className={styles.iconContainer}>
                        <FcDataEncryption className={styles.reactIcon} />
                    </div>
                    <div className={styles.content}>
                        <h2 className={styles.title}>Blockchain-powered / Storage Incident Logging</h2>
                        <p className={styles.description}>
                            Ensure the security and integrity of incident data with a tamper-proof blockchain-powered logging system.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeAdvancedFeatureTab;
