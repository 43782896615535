// HomeBannerTab.js

import React from 'react';
import styles from '../../Css/Features/FeatureIssueTrackTab.module.css';
import MonitorImage from '../../Img/issuetrack.png';
import { FcApproval } from "react-icons/fc";

const FeatureIssueTrackTab = () => {
    return (
        <div className={styles.container}>
            <div className={styles.textContainer}>
                <h2 className={styles.title2}>IncIQs's Issue Tracker</h2>
                <h2 className={styles.title}><FcApproval /> TrackIssue</h2>
                <h2 className={styles.title}><FcApproval /> AssignIssue</h2>
                <h2 className={styles.title}><FcApproval /> Report</h2>
            </div>
            <div className={styles.imageContainer}>
                <img src={MonitorImage} alt="ProductImage" className={styles.image} />
            </div>

        </div>
    );
};

export default FeatureIssueTrackTab;
