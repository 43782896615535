// HomeBannerTab.js

import React from 'react';
import styles from '../../Css/Features/FeatureDashboardTab.module.css';
import DashboeadSanpImage from '../../Img/dashboeadSanp.png';
import { FcApproval } from "react-icons/fc";

const FeatureDashboardTab = () => {
    return (
        <div>
            <h2 className={styles.title3}>Our Key Features</h2>
            <br />

            <div className={styles.container}>
                <div className={styles.imageContainer}>
                    <img src={DashboeadSanpImage} alt="ProductImage" className={styles.image} />
                </div>
                <div className={styles.textContainer}>
                    <h2 className={styles.title2}>IncIQs's Dashboard</h2>
                    <h2 className={styles.title}><FcApproval /> Executive Dashboard</h2>
                    <h2 className={styles.title}><FcApproval /> Financial Dashboard</h2>
                    <h2 className={styles.title}><FcApproval /> Compliance Dashboard</h2>
                    <h2 className={styles.title}><FcApproval /> Customer Dashboard</h2>
                    <h2 className={styles.title}><FcApproval /> Efficiency Dashboard</h2>
                    <h2 className={styles.title}><FcApproval /> Report Dashboard</h2>

                </div>
            </div>
        </div>
    );
};

export default FeatureDashboardTab;
