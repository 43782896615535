// HomeBannerTab.js

import React from 'react';
import styles from '../../Css/Home/HomeAITab.module.css';
import AIImage from '../../Img/AI2.jpg';
import { FcApproval } from "react-icons/fc";

const HomeAITab = () => {
    return (
        <div className={styles.container}>
            <div className={styles.imageContainer}>
                <img src={AIImage} alt="ProductImage" className={styles.image} />
            </div>
            <div className={styles.textContainer}>
                <h2 className={styles.title2}>IncIQs's A.I</h2>
                <h2 className={styles.title}><FcApproval /> Intelligent Incident Management</h2>
                <h2 className={styles.title}><FcApproval /> AI-Driven Incident Insights</h2>
                <h2 className={styles.title}><FcApproval /> AI-Driven Business Insights</h2>
                <h2 className={styles.title}><FcApproval /> Monitor Compliance and Security</h2>
                <h2 className={styles.title}><FcApproval /> Monitor Vulnerability </h2>
                <h2 className={styles.title}><FcApproval /> AI-Driven Performance Analytics</h2>
                <h2 className={styles.title}><FcApproval /> AI-Driven Business Impact </h2>
                <h2 className={styles.title}><FcApproval /> AI-Driven Global Incident Heatmap </h2>

            </div>
        </div>
    );
};

export default HomeAITab;
