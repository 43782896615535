import React from 'react';
import { Box, Link, IconButton } from '@mui/material';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin, FaYoutubeSquare } from 'react-icons/fa';
import styles from '../../Css/Footer/FooterInfoTab.module.css';
import logo from '../../Img/logo3.png'; // Import your logo image
import { width } from '@mui/system';

const FooterInfoTab = () => {
    return (
        <Box className={styles.container}>
            {/* First Section */}
            <div className={styles.section}>
                <div className={styles.title}>
                    <img src={logo} alt="Company Logo" className={styles.logo} /> {/* Logo */}
                    <h1>IncIQs</h1>
                </div>
                <div className={styles.text}>
                    <br />
                    <p style={{ margin: '0' }}>© 2024 <strong>IncIQs Home</strong>. All rights reserved. | <a href="https://www.inciqs.com/" style={{ color: '#FB6904' }} target="_blank" rel="noopener noreferrer">IncIQs</a></p>
                    <br />
                    <p style={{ margin: '0' }}>Design and Developed By : <strong style={{ textDecoration: 'underline' }}> Badal Tripathy</strong></p>


                </div>
            </div>

            {/* Second Section */}
            <div className={styles.section}>
                <h6 className={styles.title2}>Useful Links</h6>
                <Link href="#" className={styles.link}
                    style={{ color: '#FB4704', fontWeight: 'bold' }}
                >
                    Home
                </Link>
                <Link href="#" className={styles.link}
                    style={{ color: '#FB4704', fontWeight: 'bold' }}
                >
                    About Us
                </Link>
                <Link href="#" className={styles.link}
                    style={{ color: '#FB4704', fontWeight: 'bold' }}
                >
                    Services
                </Link>
                <Link href="#" className={styles.link}
                    style={{ color: '#FB4704', fontWeight: 'bold' }}
                >
                    Contact Us
                </Link>
            </div>

            {/* Third Section */}
            <div className={styles.section}>
                <h6 className={styles.title}>Connect with Us</h6>
                <div className={styles.socialIcons}>
                    <IconButton
                        aria-label="Facebook"
                        className={styles.icon}
                        style={{
                            cursor: 'pointer',
                            color: '#ff2f00',
                            marginRight: '10px',
                        }}
                    >
                        <FaFacebook />
                    </IconButton>

                    <IconButton aria-label="Twitter" className={styles.icon}
                        style={{
                            cursor: 'pointer',
                            color: '#ff2f00',
                            marginRight: '10px'
                        }}
                    >
                        <FaTwitter />
                    </IconButton>
                    <IconButton aria-label="Instagram" className={styles.icon}
                        style={{
                            cursor: 'pointer',
                            color: '#ff2f00',
                            marginRight: '10px',
                        }}
                    >
                        <FaInstagram />
                    </IconButton>
                    <IconButton aria-label="Linkedin" className={styles.icon}
                        style={{
                            color: '#ff2f00',
                            marginRight: '10px',
                            cursor: 'pointer',
                        }}
                    >
                        <FaLinkedin />
                    </IconButton>
                    <IconButton aria-label="Youtube" className={styles.icon}
                        style={{
                            color: '#ff2f00',
                            marginRight: '10px',
                            cursor: 'pointer',
                        }}
                    >
                        <FaYoutubeSquare />
                    </IconButton>
                </div>
            </div>
            {/* Third Section */}
            <div className={styles.section}>
                <h6 className={styles.title}>Subscribe to IncIQs Newsletter</h6>
                <form className={styles.emailForm}>
                    <input type="email" placeholder="Enter your email" className={styles.emailInput} />
                    <button type="submit" className={styles.submitButton}>Submit</button>
                </form>
            </div>
        </Box >
    );
};

export default FooterInfoTab;
