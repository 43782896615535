import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from '../../Css/Home/HomeAnnouncement.module.css';
import { FcAdvertising } from "react-icons/fc";

const HomeAnnouncement = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 400,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: true,
        arrows: false,
    };

    return (
        <div>
            <h1 className={styles.componentTitle}> <FcAdvertising /> Our Announcements</h1>
            <Slider {...settings} className={styles.slider}>
                <div className={styles.slide}>
                    <div>
                        <h2 className={styles.title}>Website Go Live</h2>
                        <p className={styles.text}>Our new website is set to launch soon! Get ready for an enhanced experience with our insider.</p>
                        <p className={styles.date}>Date : 01/05/2024</p>
                    </div>
                </div>
                <div className={styles.slide}>
                    <div>
                        <h2 className={styles.title}>Our Empoloyee Management Prortal</h2>
                        <p className={styles.text}>Exciting news! Our new Employee Management Portal is on its way! Stay tuned for a seamless and efficient experience internal operations.</p>
                        <p className={styles.date}>Date: 10/15/2024</p>
                    </div>
                </div>
            </Slider>
        </div>
    );
};

export default HomeAnnouncement;
