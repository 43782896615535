// HomeBannerTab.js

import React from 'react';
import styles from '../../Css/About/AboutTab.module.css';
import dashboardImage from '../../Img/about.jpg';
import { FcElectronics, FcMindMap, FcOrgUnit } from "react-icons/fc";

const AboutTab = () => {
    return (
        <div className={styles.container}>
            <div className={styles.imageContainer}>
                <img src={dashboardImage} alt="ProductImage" className={styles.image} />
            </div>
            <div className={styles.textContainer}>
                <h2 className={styles.title2}>About IncIQs</h2>
                <p className={styles.ptext}>
                    Welcome to IncIQs, the groundbreaking solution poised to transform incident response across industries.
                    IncIQs is not just a platform; it's an intelligent incident management revolution designed to tackle the myriad
                    challenges organizations face today.
                    In a landscape riddled with the complexities of IT environments, overwhelming alert volumes, and the limitations of manual processes,
                    IncIQs emerges as a beacon of efficiency and intelligence. Our mission is clear:
                    streamline incident response, empower teams with predictive analytics and AI-driven insights, and provide a
                    comprehensive solution to the diverse spectrum of incident management challenges. IncIQs isn't just a product;
                    it's a commitment to enhancing operational stability, mitigating downtime costs,
                    and fortifying against reputational and compliance risks.</p>
                    <br/>
                <h2 className={styles.title}><FcElectronics /> Innovative Algorithms</h2>
                <p className={styles.ptext}>
                    IncIQs employs cutting-edge algorithms for predictive incident analytics. By forecasting potential incidents, IncIQs enables organizations to proactively address issues before they escalate.
                </p>
                <h2 className={styles.title}><FcMindMap /> Comprehensive Automation</h2>
                <p className={styles.ptext}>
                    IncIQs offers end-to-end automation, from alert triage to resolution. Users can tailor automation workflows, ensuring adaptability to diverse incident scenarios.
                </p>
                <h2 className={styles.title}><FcOrgUnit /> Dynamic Ecosystem Integration</h2>
                <p className={styles.ptext}>
                    IncIQs pioneers an Ecosystem Marketplace, offering a range of third-party add-ons and integrations. Businesses can dynamically scale their incident management capabilities by seamlessly integrating with a diverse array of tools and services.
                </p>
            </div>
        </div>
    );
};

export default AboutTab;
