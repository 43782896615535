import React from 'react'
import Header from './Main/Header'
import ContactTab from '../Component/Contact/ContactTab'

export default function Contact() {
  return (
    <div>
        <Header/>
        <ContactTab/>
    </div>
  )
}
