import React from 'react'
import Header from './Main/Header'
import KeyFeaturesTab from '../Component/Features/KeyFeaturesTab'
import FeatureDashboardTab from '../Component/Features/FeatureDashboardTab'
import FeatureMonitorTab from '../Component/Features/FeatureMonitorTab'
import FeatureCRTab from '../Component/Features/FeatureCRTab'
import FeatureIssueTrackTab from '../Component/Features/FeatureIssueTrackTab'
import FeatureNotificationTab from '../Component/Features/FeatureNotificationTab'
import FeatureSupportTab from '../Component/Features/FeatureSupportTab'
import FeatureOptionTab from '../Component/Features/FeatureOptionTab'
import FeatureAllMonitorTab from '../Component/Features/FeatureAllMonitorTab'
import FeatureRealTimeTab from '../Component/Features/FeatureRealTimeTab'
import FeatureUserImpactTab from '../Component/Features/FeatureUserImpactTab'
import FeatureIntegrationTab from '../Component/Features/FeatureIntegrationTab'
import FeatureIntegrationTabTwo from '../Component/Features/FeatureIntegrationTabTwo'

export default function Features() {
  return (
    <div>
      <Header/>
      <KeyFeaturesTab/>
      <FeatureOptionTab/>
      <FeatureDashboardTab/>
      <FeatureMonitorTab/>
      <FeatureCRTab/>
      <FeatureIssueTrackTab/>
      <FeatureIntegrationTab/>
      <FeatureIntegrationTabTwo/>
      <FeatureAllMonitorTab/>
      <FeatureRealTimeTab/>
      <FeatureNotificationTab/>
      <FeatureSupportTab/>
    </div>
  )
}
