// HomeBannerTab.js

import React from 'react';
import styles from '../../Css/Features/FeatureIssueTrackTab.module.css';
import IntegrationImg from '../../Img/Integration2.png';
import { FcApproval } from "react-icons/fc";

const FeatureIntegrationTabTwo = () => {
    return (
        <div className={styles.container}>
            <div className={styles.textContainer}>
                <h2 className={styles.title2}>Languages we support</h2>
                <h2 className={styles.title}><FcApproval />
                    We provide robust language support, including multiple languages, ensuring compatibility and efficiency within your environments.
                </h2>
                <h2 className={styles.title}><FcApproval />
                    We support 50+ Language and Frameworks
                </h2>
            </div>
            <div className={styles.imageContainer}>
                <img src={IntegrationImg} alt="ProductImage" className={styles.image} />
            </div>

        </div>
    );
};

export default FeatureIntegrationTabTwo;
