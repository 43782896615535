import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './Page/Home/Home';
import About from './Page/About';
import Contact from './Page/Contact';
import Pricing from './Page/Pricing';
import FAQ from './Page/FAQ';
import Features from './Page/Features';
import Career from './Page/Career';
import IncBlog from './Page/IncBlog';

const App = () => {

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/About" element={<About />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Pricing" element={<Pricing />} />
        <Route path="/FAQ" element={<FAQ />} />
        <Route path="/Features" element={<Features />} />
        <Route path="/Career" element={<Career />} />
        <Route path="/IncBlog" element={<IncBlog />} />
      </Routes>
    </Router>
  );
};

export default App;
