// HomeBannerTab.js

import React from 'react';
import styles from '../../Css/Features/FeatureOptionTab.module.css';
import OptionImage from '../../Img/option.png';
import ProductImage from '../../Img/product.png';
import { FcBriefcase } from "react-icons/fc";

const FeatureOptionTab = () => {
    return (
        <div className={styles.container}>
        
            <div className={styles.textContainer}>
                <h2 className={styles.title2}>Our Key Options</h2>
                <h2 className={styles.title}><FcBriefcase /> Our A.I</h2>
                <h2 className={styles.title}><FcBriefcase /> Product Config</h2>
                <h2 className={styles.title}><FcBriefcase /> Team Config</h2>
                <h2 className={styles.title}><FcBriefcase /> Dashboards</h2>
                <h2 className={styles.title}><FcBriefcase /> Dashboards Setup</h2>
                <h2 className={styles.title}><FcBriefcase /> INCTicket</h2>
                <h2 className={styles.title}><FcBriefcase /> Team Tracker</h2>
                <h2 className={styles.title}><FcBriefcase /> Compliance</h2>
                <h2 className={styles.title}><FcBriefcase /> Monitor</h2>
                <h2 className={styles.title}><FcBriefcase /> Notification</h2>
                <h2 className={styles.title}><FcBriefcase /> User Impact</h2>
                <h2 className={styles.title}><FcBriefcase /> Change Request</h2>
                <h2 className={styles.title}><FcBriefcase /> Customer Monitor Setup</h2>
                <h2 className={styles.title}><FcBriefcase /> Realtime Charts</h2>
                <h2 className={styles.title}><FcBriefcase /> Support</h2>
                <h2 className={styles.title}><FcBriefcase /> User Manual</h2>
                <h2 className={styles.title}><FcBriefcase /> Profile</h2>
                <h2 className={styles.title}><FcBriefcase /> Quick Option</h2>
                <h2 className={styles.title}><FcBriefcase /> User Setting</h2>
                <h2 className={styles.title}><FcBriefcase /> Announcement </h2>
                <h2 className={styles.title}><FcBriefcase /> Mail Notification</h2>
                <h2 className={styles.title}>Much more..</h2>

            </div>

            <div className={styles.imageContainer}>
                <img src={OptionImage} alt="OptionImage" className={styles.image} />
                <img src={ProductImage} alt="ProductImage" className={styles.image} />
            </div>
        </div>
    );
};

export default FeatureOptionTab;
