// HomeBannerTab.js

import React from 'react';
import styles from '../../Css/Features/FeatureNotificationTab.module.css';
import NotificationImage from '../../Img/Notification.png';
import { FcApproval } from "react-icons/fc";

const FeatureNotificationTab = () => {
    return (
        <div className={styles.container}>
            <div className={styles.imageContainer}>
                <img src={NotificationImage} alt="NotificationImage" className={styles.image} />
            </div>
            <div className={styles.textContainer}>
                <h2 className={styles.title2}>IncIQs's Notification</h2>
                <h2 className={styles.title}><FcApproval /> AddSME</h2>
                <h2 className={styles.title}><FcApproval /> Escalation Setup</h2>
                <h2 className={styles.title}><FcApproval /> Mobile Setup</h2>

            </div>
        </div>
    );
};

export default FeatureNotificationTab;
