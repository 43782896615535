import React from 'react'
import Header from './Main/Header'
import AboutTab from '../Component/About/AboutTab'

export default function About() {
  return (
    <div>
      <Header/>
      <AboutTab/>
    </div>
  )
}
